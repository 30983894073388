@charset "UTF-8";
/**
 * screen.scss
 * primary stylesheet for 501 Commons responsive Plone theme
 */
/*******************************************
 *
 * @IMPORTS
 * SASS partials containing variables, mixins, base css, etc.
 *
 *******************************************/
/* Breakpoint sans Compass */
/* variables */
/**
 * _breakpoints.scss
 * Implement named breakpoints. Assumes you are using the Breakpoint library to compile
 *
 * Basic queries are always min-width (so that size or larger)
 * syntax: @include breakpoint( {$size} ) { // css rules }
 */
/* Breakpoint library config */
/* Named Media Queries */
/* Retina */
/* Print Styles */
/**
 * _colors.scss
 * color variables and any color-related SASS mix-ins
 */
/* Primary Palette */
/* Secondary Palette */
/* Other */
/* Social */
/* Social as a list */
/* override defaults in _sanitize.scss */
/* styles */
/**
 * grid.scss
 * a super simple grid and spacer layout
 */
/* clear the floats */
/* https://css-tricks.com/snippets/css/clear-fix/ */
.clearfix,
.col-row,
.site-footer,
.faceted-center-column .faceted-widget {
  clear: both;
}

.clearfix:before, .clearfix:after,
.col-row:before,
.site-footer:before,
.faceted-center-column .faceted-widget:before,
.col-row:after,
.site-footer:after,
.faceted-center-column .faceted-widget:after {
  content: "";
  display: table;
  clear: both;
}

/* screen-reader-text */
.screen-reader-text,
#faceted-center-column .widget-fieldset legend {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/* Add to screen-reader-text class for things like skip-to-content links */
.focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  z-index: 100000;
  top: 10px;
  left: 5%;
  padding: .5em 1em;
  background: #f2f2f2;
  border: #bcbdc1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
}

[tabindex="-1"]:focus {
  outline: 0;
}

/* lobotomized owl - alistapart.com/article/axiomatic-css-and-lobotomized-owls */
* + * {
  margin-top: 1.5em;
}

/* responsive images */
img {
  max-width: 100%;
  height: auto;
}

/* Super Simple "Grid" System */
/* https://css-tricks.com/dont-overthink-it-grids/ */
.col-row {
  margin-top: 1rem;
}

@media screen and (min-width: 40.625em) {
  .flexbox .col-row {
    display: flex;
  }
}

@media screen and (min-width: 40.625em) {
  .col {
    float: left;
    margin: 0;
    padding: 0 0.3333333333rem;
  }
  .flexbox .col {
    float: none;
    flex: 1;
  }
  .col-1-2 {
    width: 50%;
  }
  .col-1-3 {
    width: 33%;
  }
}

/*! sanitize.css v2.0.0 | CC0 1.0 Public Domain | github.com/jonathantneal/sanitize.css */
/*
 * Normalization
 */
:root {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
}

audio:not([controls]) {
  display: none;
}

button {
  overflow: visible;
}

details {
  display: block;
}

input[type="number"] {
  width: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

main {
  display: block;
}

summary {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

small {
  font-size: 75%;
}

template {
  display: none;
}

textarea {
  overflow: auto;
}

[hidden] {
  display: none;
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

/*
 * Opinionated defaults
 */
*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  cursor: default;
  font-size: 100%;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #f2f2f2;
  color: #222;
}

a {
  text-decoration: underline;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  background-color: #fff;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

nav ol,
nav ul {
  list-style: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: vertical;
}

::selection {
  background-color: #004158;
  color: #fff;
  text-shadow: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

/**
 * _typography.scss
 * type-related mixins and variables as well as all plain HTML element styling
 * used in both screen.css and tiny-mce.css
 */
/* Breakpoint sans Compass */
/* Partials I use with type */
/**
 * _colors.scss
 * color variables and any color-related SASS mix-ins
 */
/* Primary Palette */
/* Secondary Palette */
/* Other */
/* Social */
/* Social as a list */
/**
 * _breakpoints.scss
 * Implement named breakpoints. Assumes you are using the Breakpoint library to compile
 *
 * Basic queries are always min-width (so that size or larger)
 * syntax: @include breakpoint( {$size} ) { // css rules }
 */
/* Breakpoint library config */
/* Named Media Queries */
/* Retina */
/* Print Styles */
/**
 * grid.scss
 * a super simple grid and spacer layout
 */
/* clear the floats */
/* https://css-tricks.com/snippets/css/clear-fix/ */
.clearfix,
.col-row,
.site-footer,
.faceted-center-column .faceted-widget {
  clear: both;
}

.clearfix:before, .clearfix:after,
.col-row:before,
.site-footer:before,
.faceted-center-column .faceted-widget:before,
.col-row:after,
.site-footer:after,
.faceted-center-column .faceted-widget:after {
  content: "";
  display: table;
  clear: both;
}

/* screen-reader-text */
.screen-reader-text,
#faceted-center-column .widget-fieldset legend {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/* Add to screen-reader-text class for things like skip-to-content links */
.focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  z-index: 100000;
  top: 10px;
  left: 5%;
  padding: .5em 1em;
  background: #f2f2f2;
  border: #bcbdc1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
}

[tabindex="-1"]:focus {
  outline: 0;
}

/* lobotomized owl - alistapart.com/article/axiomatic-css-and-lobotomized-owls */
* + * {
  margin-top: 1.5em;
}

/* responsive images */
img {
  max-width: 100%;
  height: auto;
}

/* Super Simple "Grid" System */
/* https://css-tricks.com/dont-overthink-it-grids/ */
.col-row {
  margin-top: 1rem;
}

@media screen and (min-width: 40.625em) {
  .flexbox .col-row {
    display: flex;
  }
}

@media screen and (min-width: 40.625em) {
  .col {
    float: left;
    margin: 0;
    padding: 0 0.3333333333rem;
  }
  .flexbox .col {
    float: none;
    flex: 1;
  }
  .col-1-2 {
    width: 50%;
  }
  .col-1-3 {
    width: 33%;
  }
}

/*******************************************
 *
 * Root Styles
 *
 *******************************************/
:root {
  font-size: .875rem !important;
}

@media screen and (min-width: 28.125em) {
  :root {
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 65.625em) {
  :root {
    font-size: 1.125rem !important;
  }
}

body {
  font-size: .9375rem;
}

.site-sidebar {
  font-size: .875rem;
}

/*******************************************
 *
 * Block Level Elements
 *
 *******************************************/
/* Headings */
h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: 1.625rem;
}

.breadcrumbs + h1 {
  margin-top: 0;
}

.subtitle {
  margin-top: 0;
  color: #6d6e6f;
}

h2 {
  color: #4a433a;
  font-size: 1.25rem;
  /* don't let the border overlap floated elements
    https://perishablepress.com/margin-list-floated-image/ */
  width: auto;
  overflow: hidden;
}

h2:after,
.home-news__title:after {
  content: '';
  display: block;
  height: 3px;
  background: url(../img/dotted.png) repeat-x;
}

h3 {
  color: #4a433a;
  font-size: 1.125rem;
}

h4,
h5,
h6 {
  font-size: 1rem;
}

h5,
h6 {
  font-weight: 400;
}

h6 {
  font-style: italic;
}

/* Lists */
main ul,
main ol {
  margin-left: 1.5rem;
}

main ul li + li,
main ol li + li {
  margin-top: .5em;
}

main ul {
  list-style: square;
}

main ul ul {
  list-style: circle;
}

main ul ul ul {
  list-style: disc;
}

main dl > * + * {
  margin-top: .5em;
}

main dt {
  font-weight: bold;
}

main dd {
  margin-left: 1.5em;
}

nav ul {
  list-style: none !important;
  margin-left: 0;
}

.inline-list {
  margin-left: 0;
}

.inline-list li {
  margin-top: 0;
  list-style: none;
  display: inline;
}

.inline-list--pipes li:after {
  display: inline-block;
  content: '|';
  margin: 0 .5em;
}

.inline-list--pipes li:last-child:after {
  display: none;
}

/* Other Block-Level Formats */
blockquote {
  font-size: 1.125rem;
  margin-left: 1.5em;
  color: #6e6458;
}

address {
  font-style: normal;
}

br {
  margin-top: 0;
}

/*******************************************
 *
 * Inlign Elements
 *
 *******************************************/
small {
  font-size: 80%;
}

/* Bold & Italic */
em em, em i, i em, i i {
  font-style: normal;
}

.meta,
.documentByLine,
.template-search #searchform .documentLocation {
  font-size: .875rem;
  font-style: italic;
  color: #4a433a;
}

/* Links */
a {
  color: #2f637a;
}

a:hover {
  text-decoration: none;
  color: #2f637a;
}

/*******************************************
 *
 * Buttons
 *
 *******************************************/
.button {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #bcbdc1;
  padding: 0 .25em;
  line-height: 1.5em;
  transition: all .2s ease-in-out;
  /* the arrow */
}

@media screen and (min-width: 28.125em) {
  .button {
    position: relative;
    padding: 0 2.75em .1em .75em;
    display: inline-block;
    /* the color block on the right */
  }
  .button:after {
    position: absolute;
    display: inline-block;
    content: '';
    top: 0;
    right: 0;
    width: 2em;
    height: 100%;
    background-color: #bcbdc1;
    transition: background-color .2s ease-in-out;
  }
}

@media screen and (min-width: 40.625em) {
  .button {
    padding: .2em 2.75em .3em .75em;
  }
}

.button .icon {
  display: inline-block;
  width: .5em;
  height: 1em;
  margin-left: 5px;
  fill: #2f637a;
}

@media screen and (min-width: 28.125em) {
  .button .icon {
    fill: #fff;
    position: absolute;
    width: .75em;
    height: 2em;
    right: .25em;
    top: 50%;
    margin-top: -1em;
    z-index: 1;
  }
}

.button:hover, .button:focus {
  border-color: #2f637a;
}

.button:hover:after, .button:focus:after {
  background-color: #2f637a;
}

.button:hover .icon, .button:focus .icon {
  fill: #e32f25;
}

@media screen and (min-width: 28.125em) {
  .button:hover .icon, .button:focus .icon {
    fill: #fff;
  }
}

/* Variants */
.button--block {
  display: block;
}

.button--dark {
  background-color: #4a433a;
  border-color: #2f637a;
  color: #fff;
}

.button--dark:after {
  background-color: #2f637a;
}

.button--dark:hover {
  color: #fff;
  border-color: #004158;
  background-color: #2f637a;
}

@media screen and (min-width: 28.125em) {
  .button--dark:hover {
    background-color: #4a433a;
  }
}

.button--dark:hover:after {
  background-color: #004158;
}

.button--dark:hover .icon {
  fill: white;
}

.button--dark .icon {
  fill: white;
}

.button--social {
  padding: .25rem .5rem;
  margin: 0 .5rem .5rem 0;
  min-height: 2rem;
  border: 0;
  font-weight: bold;
  color: #fff;
  transition: background-color .2s ease-in-out;
}

.button--social .icon {
  position: static;
  width: 1.5rem;
  fill: #fff;
  margin: 0;
}

.button--social:after {
  display: none;
}

.button--social:hover, .button--social:focus {
  color: #fff;
  background-color: #004158;
}

.button--help {
  min-height: 2.2rem;
  padding: .4em 2.75em .3em 3.25em;
  font-weight: bold;
  line-height: 1;
}

.button--help .icon-help {
  width: 1.5rem;
  left: .25rem;
  right: auto;
}

.button--help span {
  display: block;
  margin-top: 0;
  font-weight: normal;
  font-style: italic;
  font-size: .5rem;
  line-height: 2;
}

.button--facebook {
  background-color: #3B5999;
}

.button--twitter {
  background-color: #55ACEE;
}

.button--google-plus {
  background-color: #dd4b39;
}

.button--youtube {
  background-color: #cd201f;
}

.button--linkedin {
  background-color: #007bb6;
}

.button--pinterest {
  background-color: #910101;
}

.button--instagram {
  background-color: #517fa4;
}

.button--blog {
  background-color: #99ad00;
}

.button--newsletter {
  background-color: #d46300;
}

.site-footer .button--social {
  float: left;
}

.site-footer .button--blog {
  clear: left;
}

.site-footer .button--twitter-tech {
  margin-left: -.4rem;
}

/* Alignments */
/* Some rules from plone */
@media screen and (min-width: 28.125em) {
  .image-left {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }
  .image-right {
    float: right;
    margin: 0.5em;
  }
  .image-inline {
    float: none;
  }
  .image-right,
  .image-left {
    max-width: 50%;
  }
  /*note: the tricky thing here is that image-inline|image-left|image-right
    can both be on an image and a wrapper element e.g. a dl tag*/
  .captioned img {
    border: 1px solid #ddd;
  }
  dd.image-caption {
    text-align: left;
    padding: 0 0 0 3px;
    /*image padding+border with*/
    margin: 0;
    color: #666;
    font-size: 85%;
  }
}

@media screen and (min-width: 40.625em) {
  .image-right,
  .image-left {
    max-width: 60%;
  }
}

/* @end */
/*******************************************
 *
 * Tables
 *
 *******************************************/
/* @group Data tables (from plone public.css) */
table.listing,
table.plain {
  border-spacing: 0;
  border-collapse: collapse;
}

table.invisible td,
table.invisible th,
table.plain td,
table.plain th,
table.listing td,
table.listing th {
  padding: 0.5em 1em;
  vertical-align: top;
}

table.plain th,
table.plain td {
  border: 1px solid #ddd;
}

table.listing td.listingCheckbox,
table.listing th.listingCheckbox {
  text-align: center;
}

table.listing {
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

table.listing .top {
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

table.listing th {
  text-align: left;
  color: #666;
  border: 1px solid #e7e7e7;
  border-style: solid solid none;
  background: #ddd;
}

table.listing tr.odd th {
  border: 0.1em solid #fff;
  border-style: solid solid none none;
}

table.listing tbody th {
  font-weight: bold;
}

table.listing tbody tr {
  text-align: left;
}

table.listing tbody tr.odd {
  background: #eee;
}

table.listing tbody tr td {
  background: transparent;
}

table.listing tbody tr.odd td {
  background: #eee;
}

table.listing td {
  border-right: 1px solid #ddd;
}

.icons-on table.listing td {
  line-height: 2em;
}

table.listing a {
  border: none;
  display: inline-block;
  border-bottom: none !important;
}

table.listing td.draggable {
  text-shadow: White 1px 1px 1px;
  background: #ddd !important;
  border-bottom: 1px solid white;
  padding: 0.4em 0.4em 0 0.5em;
}

/* Vertical table data listings */
table.vertical th {
  text-align: right;
}

table.vertical td {
  border-top: 0.1em solid #e7e7e7;
  padding: 0.5em;
}

/* Grid table listings */
table.grid td,
table.grid th {
  border: 1px solid #e7e7e7;
  padding: 0.5em;
}

/* @end */
th {
  text-align: left;
}

th,
td {
  padding: 0.5rem;
}

table.striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.input_tiny {
  width: 50px;
}

.input_small {
  width: 100px;
}

.input_medium {
  width: 150px;
}

.input_large {
  width: 200px;
}

.input_xlarge {
  width: 250px;
}

.input_xxlarge {
  width: 300px;
}

.input_full {
  width: 100%;
}

.input_full_wrap {
  display: block;
  padding-right: 8px;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input:invalid,
button:invalid,
select:invalid,
textarea:invalid {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input:focus,
button:focus,
select:focus,
textarea:focus {
  -webkit-box-shadow: #0066ff 0 0 5px 0;
  -moz-box-shadow: #0066ff 0 0 5px 0;
  box-shadow: #0066ff 0 0 5px 0;
  z-index: 1;
}

input[type="file"]:focus, input[type="file"]:active,
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="checkbox"]:focus,
input[type="checkbox"]:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #dddddd));
  background-image: -webkit-linear-gradient(#ffffff, #dddddd);
  background-image: -moz-linear-gradient(#ffffff, #dddddd);
  background-image: -o-linear-gradient(#ffffff, #dddddd);
  background-image: linear-gradient(#ffffff, #dddddd);
  border: 1px solid;
  border-color: #dddddd #bbbbbb #999999;
  cursor: pointer;
  color: #333333;
  display: inline-block;
  outline: 0;
  overflow: visible;
  margin: 0;
  padding: 3px 10px;
  text-shadow: white 0 1px 1px;
  text-decoration: none;
  vertical-align: top;
  width: auto;
}

button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(1px, #eeeeee), color-stop(100%, #cccccc));
  background-image: -webkit-linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  background-image: -moz-linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  background-image: -o-linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  background-image: linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  text-decoration: none;
}

button:active,
input[type="reset"]:active,
input[type="submit"]:active,
input[type="button"]:active {
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #dddddd), color-stop(100%, #eeeeee));
  background-image: -webkit-linear-gradient(#dddddd, #eeeeee);
  background-image: -moz-linear-gradient(#dddddd, #eeeeee);
  background-image: -o-linear-gradient(#dddddd, #eeeeee);
  background-image: linear-gradient(#dddddd, #eeeeee);
  -webkit-box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
  -moz-box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
  box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
  border-color: #999999 #bbbbbb #dddddd;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid;
  border-color: #848484 #c1c1c1 #e1e1e1;
  outline: 0;
  margin: 0;
  padding: 3px;
  text-align: left;
  min-height: 1.8em;
  vertical-align: top;
}

textarea[disabled],
select[disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="email"][disabled],
input[type="month"][disabled],
input[type="number"][disabled],
input[type="password"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
input[type="week"][disabled] {
  background-color: #eeeeee;
}

button[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled] {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  color: #888888;
  cursor: default;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #888888;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #888888;
}

input.placeholder_text,
textarea.placeholder_text {
  color: #888888;
}

textarea,
select[size],
select[multiple] {
  height: auto;
}

select[size="0"],
select[size="1"] {
  height: 1.8em;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  select[size],
  select[multiple],
  select[multiple][size] {
    background-image: none;
    padding-right: 3px;
  }
  select,
  select[size="0"],
  select[size="1"] {
    background-image: url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
  }
  ::-webkit-validation-bubble-message {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #666666), color-stop(1, black));
    border: 0;
    color: white;
    font: 13px/17px "Lucida Grande", Arial, "Liberation Sans", FreeSans, sans-serif;
    overflow: hidden;
    padding: 15px 15px 17px;
    text-shadow: black 0 0 1px;
    min-height: 16px;
  }
  ::-webkit-validation-bubble-arrow,
  ::-webkit-validation-bubble-top-outer-arrow,
  ::-webkit-validation-bubble-top-inner-arrow {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #666666;
    border-color: #666666;
  }
}

textarea {
  min-height: 40px;
  overflow: auto;
  resize: vertical;
  width: 100%;
}

optgroup {
  color: black;
  font-style: normal;
  font-weight: normal;
}

optgroup::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
textarea {
  border: 1px solid #bcbdc1;
}

.plain-button,
.search-form button {
  background: transparent;
  border: 0;
}

.plain-button:hover, .plain-button:focus,
.search-form button:hover,
.search-form button:focus {
  background: transparent;
  border: 0;
}

/* hide the SVG sprite */
#svg-defs {
  display: none;
}

.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 0.25em;
  vertical-align: middle;
  fill: #fff;
}

svg.icon--green {
  fill: #99ad00;
}

svg.icon--orange {
  fill: #d46300;
}

svg.icon--blue {
  fill: #2f637a;
}

svg.icon--purple {
  fill: #a82c96;
}

svg.icon--slate {
  fill: #6e6458;
}

svg.icon--red {
  fill: #e32f25;
}

/* clear the floats */
/* https://css-tricks.com/snippets/css/clear-fix/ */
.clearfix,
.col-row,
.site-footer,
.faceted-center-column .faceted-widget {
  clear: both;
}

.clearfix:before, .clearfix:after,
.col-row:before,
.site-footer:before,
.faceted-center-column .faceted-widget:before,
.col-row:after,
.site-footer:after,
.faceted-center-column .faceted-widget:after {
  content: "";
  display: table;
  clear: both;
}

/* screen-reader-text */
.screen-reader-text,
#faceted-center-column .widget-fieldset legend {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/* Add to screen-reader-text class for things like skip-to-content links */
.focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  z-index: 100000;
  top: 10px;
  left: 5%;
  padding: .5em 1em;
  background: #f2f2f2;
  border: #bcbdc1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
}

[tabindex="-1"]:focus {
  outline: 0;
}

/*** ESSENTIAL STYLES ***/
.sf-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
  text-align: left;
}

@media screen and (min-width: 40.625em) {
  .sf-menu {
    order: 100;
    font-weight: bold;
  }
}

@media screen and (min-width: 57.8125em) {
  .sf-menu {
    flex: 1 1 auto;
    justify-content: flex-end;
    order: 0;
  }
  .no-flexbox .sf-menu {
    display: block;
    position: absolute;
    bottom: 0;
    left: 95px;
    /* magic number :( matches left padding of parent container */
  }
}

/* top-level items only */
.sf-menu > li {
  flex: 1 1 auto;
  margin: 0 1px 1px 0;
}

@media screen and (min-width: 40.625em) {
  .sf-menu > li {
    position: relative;
  }
}

@media screen and (min-width: 57.8125em) {
  .sf-menu > li {
    flex-grow: 0;
    margin: 0 1.5rem 0 0;
  }
}

.sf-menu > li:last-child {
  margin-right: 0;
}

@media screen and (min-width: 57.8125em) {
  .sf-menu > li:last-child {
    margin-right: 2rem;
  }
}

.no-flexbox .sf-menu > li {
  float: left;
}

.sf-menu > li > a {
  padding: .25em 1em .25em .5em;
  border: 1px solid #bcbdc1;
  background-color: #f2f2f2;
}

.sf-menu > li > a:hover, .sf-menu > li > a:focus {
  border-color: #6d6e6f;
}

@media screen and (min-width: 0) and (max-width: 21.5625em) {
  .sf-menu > li > a {
    padding: .25em .5em;
    text-align: center;
  }
}

@media screen and (min-width: 57.8125em) {
  .sf-menu > li > a {
    border: 0;
    border-bottom: 5px solid transparent;
  }
  .sf-menu > li > a:hover, .sf-menu > li > a:focus {
    border-bottom: 5px solid #2f637a;
  }
}

.sf-menu a {
  display: block;
  position: relative;
  text-decoration: none;
}

.sf-menu > .sf-active > a {
  border-color: #2f637a;
}

.sf-menu > .sf-active > a:before {
  display: block;
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 100;
  background-color: #f2f2f2;
}

/* Submenus */
.sf-menu .submenu {
  display: none;
  position: absolute;
  /* top: 100%; */
  left: 0;
  z-index: 99;
  margin-top: -1px;
  background-color: #f2f2f2;
  border: 1px solid #2f637a;
  font-weight: normal;
  font-size: 0.875rem;
}

@media screen and (min-width: 0) and (max-width: 40.625em) {
  .sf-menu .submenu {
    width: 100% !important;
    /*override inline width from supersubs */
  }
}

@media screen and (min-width: 40.625em) {
  .sf-menu .submenu {
    max-width: 16rem;
    left: auto;
    right: 0;
  }
}

@media screen and (min-width: 57.8125em) {
  .sf-menu .submenu {
    margin-top: 0;
    left: 0;
    right: auto;
  }
}

.sf-menu .submenu li.submenu-description {
  padding: .5em;
  font-style: italic;
  color: #6d6e6f;
}

.sf-menu .submenu a {
  padding: .5em;
}

.sf-menu .submenu a:hover,
.sf-menu .submenu a a:focus {
  background-color: #fff;
}

.sf-menu li:hover > ul,
.sf-menu li:focus > ul,
.sf-menu li.sf-active > ul {
  display: block;
}

.sf-menu ul ul {
  top: 0;
  left: 100%;
}

/* styling for both css and generated arrows */
.sf-arrows .sf-with-ul:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: .3em;
  height: 0;
  width: 0;
  /* order of following 3 rules important for fallbacks to work */
  border-width: 5px 4px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #2f637a;
}

@media screen and (min-width: 0) and (max-width: 21.5625em) {
  .sf-arrows .sf-with-ul:after {
    position: static;
    display: block;
    margin: 5px auto 0;
  }
}

.sf-arrows .sf-active > .sf-with-ul:after {
  border-top-color: transparent !important;
  margin-top: -8px;
  border-bottom-color: #6d6e6f;
}

@media screen and (min-width: 0) and (max-width: 21.5625em) {
  .sf-arrows .sf-active > .sf-with-ul:after {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sf-active > .sf-with-ul:after {
  border-top-color: #2f637a;
  /* IE8 fallback colour */
}

.sf-arrows li.selected > .sf-with-ul:after,
.sf-arrows li.selected > .sf-with-ul:focus:after {
  border-top-color: #e32f25;
}

/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #2f637a;
}

.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sf-active > .sf-with-ul:after {
  border-left-color: #2f637a;
}

/*******************************************
 *
 * Search Form
 *
 *******************************************/
.search-form {
  position: relative;
}

.search-form input[type="text"] {
  padding: .25em 2.25rem .25rem .25rem;
  width: 100%;
  height: auto;
}

.search-form .icon {
  fill: #4a433a;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: text-top;
}

.search-form button {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.25rem;
  padding: 0 .5rem;
  height: 100%;
  opacity: .5;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
}

.search-form button:hover, .search-form button:focus {
  opacity: 1;
}

/*******************************************
 *
 * Media Object
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 *
 *******************************************/
.media * + * {
  margin-top: 0;
}

.media__image {
  display: block;
  text-align: center;
}

@media screen and (min-width: 28.125em) {
  .media {
    overflow: hidden;
  }
  .media__image {
    float: left;
    margin-right: 1rem;
    text-align: left;
    max-width: 35%;
  }
  .media__image img {
    display: block;
  }
  .media__image--right {
    float: right;
    margin-left: 1rem;
  }
  .media__body {
    overflow: hidden;
    margin: 0;
  }
}

/*******************************************
 *
 * "info box"
 *
 *******************************************/
.infobox {
  padding: 0.5rem;
  background-color: #f2f2f2;
  border: 1px solid #bcbdc1;
  font-style: italic;
  font-size: .875rem;
}

.infobox .icon {
  fill: #6e6458;
}

/*******************************************
 *
 * Cards
 *
 *******************************************/
.card {
  position: relative;
  overflow: hidden;
  padding: 1rem 1rem 1rem 4rem;
}

.card:not(.card--single) {
  font-size: .875rem;
}

.card:before {
  position: absolute;
  display: block;
  content: '';
  width: 3rem;
  top: 0;
  bottom: 0;
  left: 0;
}

.card__title svg {
  position: absolute;
  width: 2rem;
  top: 0.66rem;
  /* gross magic number. sorry */
  left: .5rem;
  fill: white;
}

.card__title h2 {
  margin-top: 0;
  font-weight: normal;
}

.card__body {
  margin-top: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__body .button {
  float: right;
  margin-left: auto;
  /* magic! https://medium.com/@samserif/flexbox-s-best-kept-secret-bd3d892826b6#.67v6sabhv */
}

@media screen and (min-width: 40.625em) {
  .card:first-of-type:last-of-type .card__body > ul {
    column-count: 2;
    margin-left: 0;
  }
  .card:first-of-type:last-of-type .card__body > ul li {
    margin-top: .5em;
    margin-left: 1rem;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .card:first-of-type:last-of-type .card__body > ul ul {
    margin-top: 0;
  }
}

@media screen and (min-width: 34.375em) {
  .card-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .card {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .card + .card,
  .card + link + .card {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card {
    padding: 0;
  }
  .card:before {
    display: none;
  }
  .card__title {
    position: relative;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .no-flexbox .card__title {
    overflow: hidden;
  }
  .card__title svg {
    position: static;
    flex: 0 0 auto;
  }
  .no-flexbox .card__title svg {
    float: left;
  }
  .card__title h2 {
    margin-left: .5rem;
    flex: 1 1 auto;
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.25;
  }
  .card__title h2:after {
    display: none;
  }
  .card__body {
    margin-top: 0;
    padding: 1rem;
    overflow: hidden;
  }
}

.card--green {
  border: 1px solid #99ad00;
}

.card--green:before {
  background-color: #99ad00;
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card--green .card__title {
    background-color: #99ad00;
  }
}

.card--orange {
  border: 1px solid #d46300;
}

.card--orange:before {
  background-color: #d46300;
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card--orange .card__title {
    background-color: #d46300;
  }
}

.card--blue {
  border: 1px solid #2f637a;
}

.card--blue:before {
  background-color: #2f637a;
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card--blue .card__title {
    background-color: #2f637a;
  }
}

.card--purple {
  border: 1px solid #a82c96;
}

.card--purple:before {
  background-color: #a82c96;
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card--purple .card__title {
    background-color: #a82c96;
  }
}

.card--slate {
  border: 1px solid #6e6458;
}

.card--slate:before {
  background-color: #6e6458;
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card--slate .card__title {
    background-color: #6e6458;
  }
}

.card--red {
  border: 1px solid #e32f25;
}

.card--red:before {
  background-color: #e32f25;
}

@media screen and (min-width: 0em) and (max-width: 21.875em), screen and (min-width: 34.375em) and (max-width: 43.75em), screen and (min-width: 56.25em) and (max-width: 65.625em) {
  .card--red .card__title {
    background-color: #e32f25;
  }
}

/* Cards no-flexbox Fallback */
@media screen and (min-width: 34.375em) {
  .no-flexbox .card-row {
    display: block;
    clear: left;
    overflow: hidden;
  }
  .no-flexbox .card {
    display: block;
    width: 47%;
    float: left;
  }
  .no-flexbox .card + .no-flexbox .card {
    margin-left: 3%;
  }
}

.no-flexbox .card--single .card {
  width: 100%;
}

/*******************************************
 *
 * Breadcrumbs
 *
 *******************************************/
.breadcrumbs {
  margin-top: 0;
  margin-bottom: -.5em;
  font-size: .75rem;
}

.breadcrumbs li:after {
  display: inline-block;
  content: '»';
  margin: 0 .25em;
  speak: none;
  vertical-align: text-bottom;
}

.breadcrumbs a {
  color: #777;
  text-decoration: none;
}

.breadcrumbs a:hover {
  color: #004158;
  text-decoration: underline;
}

/*******************************************
 *
 * Blog
 *
 *******************************************/
@media screen and (min-width: 28.125em) {
  .tileImage,
  .newsImageContainer {
    float: right;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

.documentByLine {
  margin-top: 0;
}

.portletArchivePortlet dd > ul {
  margin-top: 0;
}

.archive-year {
  margin-top: 1rem;
}

/* 2018 Blog */
#blog-features .blog-post,
#blog-listing .blog-post {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}

#blog-features .blog-title,
#blog-features .blog-meta,
#blog-features .blog-summary,
#blog-listing .blog-title,
#blog-listing .blog-meta,
#blog-listing .blog-summary {
  margin-top: .5rem;
}

#blog-features .blog-title,
#blog-listing .blog-title {
  font-size: 1rem;
}

#blog-features .blog-title:after,
#blog-listing .blog-title:after {
  display: none;
}

#blog-features .blog-meta *,
#blog-listing .blog-meta * {
  margin-top: 0;
}

#blog-features .blog-date,
#blog-listing .blog-date {
  display: inline-block;
  padding: .125rem 0;
  margin-right: .25rem;
  font-size: .75rem;
  font-weight: bold;
  color: #6d6e6f;
}

#blog-features .blog-categories,
#blog-listing .blog-categories {
  display: inline-block;
  font-size: .75rem;
}

#blog-features .blog-categories a,
#blog-listing .blog-categories a {
  display: inline-block;
  padding: .125rem .25rem;
  background-color: #f2f2f2;
  text-decoration: none;
}

#blog-features .blog-categories a:hover, #blog-features .blog-categories a:focus,
#blog-listing .blog-categories a:hover,
#blog-listing .blog-categories a:focus {
  text-decoration: underline;
}

#blog-features .blog-categories a + a,
#blog-listing .blog-categories a + a {
  margin-left: .5rem;
}

#blog-features .blog-summary,
#blog-listing .blog-summary {
  font-size: .875rem;
}

#blog-features .blog-image {
  position: relative;
  margin-top: 0;
  overflow: hidden;
  background-color: #bcbdc1;
  order: -2;
}

#blog-features .blog-image:after {
  display: block;
  content: '';
  width: 100%;
  padding-top: 60%;
}

#blog-features .blog-image img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#blog-features .blog-date {
  padding: .25rem .75rem;
  font-size: 1rem;
}

.flexbox #blog-features .blog-date {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 40.625em) {
  #blog-features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem 2rem;
    /* grid fallbacks AND styles for :first-child with grid */
  }
  #blog-features .blog-post {
    display: block;
    overflow: hidden;
    margin-bottom: 2rem;
  }
  #blog-features .blog-image {
    float: left;
    width: 65%;
    margin-right: 2%;
  }
  #blog-features .blog-title,
  #blog-features .blog-meta,
  #blog-features .blog-summary {
    float: right;
    width: 33%;
  }
  #blog-features .blog-title {
    margin-top: -.25rem;
  }
  #blog-features .blog-meta,
  #blog-features .blog-summary {
    clear: right;
  }
  #blog-features .blog-date {
    padding: .125rem .25rem;
    background: rgba(47, 99, 122, 0.85);
    color: #fff;
  }
  @supports (display: flex) {
    #blog-features {
      /* override fallbacks */
    }
    #blog-features .blog-post {
      display: flex;
      margin-top: 0;
      margin-bottom: 0;
    }
    #blog-features .blog-post:first-child {
      grid-column: 1 / -1;
      display: block;
    }
    #blog-features .blog-post:nth-child(n+2) {
      /* all but 1st one */
      /* posts 2-5 */
    }
    #blog-features .blog-post:nth-child(n+2) .blog-image,
    #blog-features .blog-post:nth-child(n+2) .blog-title,
    #blog-features .blog-post:nth-child(n+2) .blog-meta,
    #blog-features .blog-post:nth-child(n+2) .blog-summary {
      float: none;
      width: 100%;
      margin-right: 0;
    }
    #blog-features .blog-post:nth-child(n+2) .blog-title {
      margin-top: 0;
    }
  }
}

#blog-listing .pagination {
  margin-top: 2rem;
}

.blog-category-list {
  background-color: #f2f2f2;
  padding-bottom: 1.5rem;
}

.blog-category-list h2 {
  padding: .5rem 1rem;
  background-color: #2f637a;
  font-size: 1rem;
  color: #fff;
}

.blog-category-list h2:after {
  display: none;
}

.blog-category-list ul {
  list-style: none;
}

/*******************************************
 *
 * Search
 *
 *******************************************/
.template-search #searchform {
  max-width: 45rem;
  margin: 0 auto;
}

.template-search #searchform .documentFirstHeading {
  margin: 1rem 0;
}

.template-search #searchform .documentLocation {
  display: block;
}

.template-search #searchform dt {
  margin-top: 1rem;
}

.template-search #searchform dd {
  margin: 0;
}

/**
 * _layout.scss
 * style the major structural elements on the site
 */
/**
 * _breakpoints.scss
 * Implement named breakpoints. Assumes you are using the Breakpoint library to compile
 *
 * Basic queries are always min-width (so that size or larger)
 * syntax: @include breakpoint( {$size} ) { // css rules }
 */
/* Breakpoint library config */
/* Named Media Queries */
/* Retina */
/* Print Styles */
body {
  margin-top: 0;
}

/* Overall Layout */
.container {
  max-width: 66.666666rem;
  margin: 0 auto 1rem;
}

@media screen and (min-width: 28.125em) {
  .container {
    width: 90%;
  }
}

main {
  padding: 1rem;
  margin-top: 0;
  border-top: 8px solid #2f637a;
  background-color: #fff;
}

/* Right sidebar on large screens */
@media screen and (min-width: 57.8125em) {
  .site-content,
  .site-sidebar {
    float: left;
    margin-top: 0;
  }
  .site-content {
    width: 70%;
    padding-right: 1rem;
  }
  .site-sidebar {
    width: 30%;
    margin-top: 0;
    padding-left: 1rem;
  }
  .site-sidebar--left {
    padding-left: 0;
    padding-right: 1rem;
  }
}

/* Header */
.site-header {
  position: relative;
  padding-top: 0.5rem;
  z-index: 1;
}

.site-header * {
  margin-top: 0;
}

.site-title {
  position: relative;
  width: 115px;
  margin: 0 auto 0.5rem;
  z-index: 100;
}

@media screen and (min-width: 18.75em) {
  .site-title {
    float: left;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 40.625em) {
  .site-title {
    width: 133px;
  }
}

@media screen and (min-width: 57.8125em) {
  .site-title {
    width: 155px;
  }
}

.site-title a {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 56.1290323%;
}

.site-title svg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.site-title path {
  transition: fill .3s ease-in-out;
}

.site-title a:hover path,
.site-title a:focus path {
  fill: #2f637a;
}

.site-title a:hover path[fill="#ED3424"],
.site-title a:focus path[fill="#ED3424"] {
  fill: #e32f25;
}

.site-tagline {
  display: none;
  font-size: .75rem;
  color: #6d6e6f;
}

.site-tagline span {
  color: #e32f25;
  font-weight: bold;
}

@media screen and (min-width: 40.625em) {
  .site-tagline {
    position: relative;
    display: block;
    float: right;
    top: -.25rem;
    width: 65%;
    text-align: right;
  }
}

@media screen and (min-width: 57.8125em) {
  .site-tagline {
    width: auto;
    top: .25rem;
    right: 32%;
    font-size: .875rem;
  }
}

/* Sidebar */
.site-subnav {
  position: relative;
  overflow: hidden;
  padding: 1rem 0;
  font-size: .875rem;
  /* current item */
  /* two col on medium */
  /* shadows */
}

.site-subnav * {
  margin-left: 0;
}

.site-subnav .portletWrapper,
.site-subnav .portletItem {
  margin-top: 0;
}

.site-subnav a {
  color: #6d6e6f;
  text-decoration: none;
}

.site-subnav a:hover {
  color: #2f637a;
  text-decoration: underline;
}

.site-subnav ul {
  padding-left: 0;
}

.site-subnav li {
  padding-left: 1rem;
}

.site-subnav ul ul {
  margin: 0.5em 0 0 1rem;
}

.site-subnav ul ul li + li {
  margin-top: .5em;
  margin-bottom: 0;
}

.site-subnav li.navTreeCurrentNode {
  padding-left: 0;
}

.site-subnav li.navTreeCurrentNode > a {
  display: block;
  padding: 0.5em 0 0.5em 1rem;
  border-left: 5px solid #bcbdc1;
  background: #f2f2f2;
  pointer-events: none;
  cursor: default;
}

@media screen and (min-width: 40.625em) {
  .site-subnav .navTree {
    column-count: 2;
    column-gap: 1rem;
  }
  .site-subnav .navTree ul {
    column-count: 1;
  }
  .site-subnav .navTree li {
    break-inside: avoid;
  }
}

@media screen and (min-width: 57.8125em) {
  .site-subnav .navTree {
    column-count: 1;
  }
}

.site-subnav:before, .site-subnav:after {
  position: absolute;
  content: '';
  display: block;
  width: 90%;
  height: 10px;
  left: 5%;
  top: -10px;
  border-radius: 100% / 20px;
  box-shadow: 0 1px 12px #bbb;
}

.site-subnav:after {
  top: auto;
  bottom: -10px;
  box-shadow: 0 -1px 12px #bbb;
}

@media screen and (min-width: 57.8125em) {
  .site-subnav:before {
    width: 10px;
    height: 90%;
    top: 5%;
    left: -10px;
    border-radius: 20px / 100%;
    box-shadow: 1px 0 12px #bbb;
  }
  .site-subnav:after {
    display: none;
  }
}

/* Main Navigation */
.main-navigation {
  width: 100%;
  clear: both;
  text-align: center;
  font-size: .875rem;
}

@media screen and (min-width: 28.125em) {
  .main-navigation {
    text-align: left;
  }
}

@media screen and (min-width: 40.625em) {
  .main-navigation {
    display: flex;
    flex-wrap: wrap;
    float: right;
    clear: none;
    width: 70%;
    margin-top: 0;
    font-size: .9375rem;
  }
}

@media screen and (min-width: 57.8125em) {
  .main-navigation {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-left: 95px;
    z-index: 0;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    float: none;
  }
}

.main-navigation .button--donate,
.main-navigation .button--volunteer {
  font-weight: bold;
}

@media screen and (min-width: 18.75em) {
  .main-navigation .button--donate,
  .main-navigation .button--volunteer {
    flex: 0 0 auto;
    position: absolute;
    top: 0.5rem;
    right: .5rem;
  }
}

@media screen and (min-width: 28.125em) {
  .main-navigation .button--donate,
  .main-navigation .button--volunteer {
    right: 0;
  }
}

@media screen and (min-width: 40.625em) {
  .main-navigation .button--donate,
  .main-navigation .button--volunteer {
    position: relative;
    top: 0;
    flex: 1 1 auto;
    margin-left: 1px;
    margin-bottom: 1px;
  }
}

@media screen and (min-width: 57.8125em) {
  .main-navigation .button--donate,
  .main-navigation .button--volunteer {
    margin-bottom: 5px;
    flex: 0 0 auto;
  }
  .no-flexbox .main-navigation .button--donate, .no-flexbox
  .main-navigation .button--volunteer {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
  }
}

.main-navigation .button--donate {
  right: 6rem;
}

@media screen and (min-width: 28.125em) {
  .main-navigation .button--donate {
    right: 7.5rem;
  }
}

@media screen and (min-width: 40.625em) {
  .main-navigation .button--donate {
    right: 0;
  }
}

@media screen and (min-width: 57.8125em) {
  .main-navigation .button--donate {
    margin-right: 0.5rem;
  }
  .no-flexbox .main-navigation .button--donate {
    position: absolute;
    right: 7.5rem;
  }
}

@media screen and (min-width: 18.75em) {
  .main-navigation .search-form {
    position: absolute;
    right: .5rem;
    top: 3rem;
    max-width: 50%;
  }
}

@media screen and (min-width: 28.125em) {
  .main-navigation .search-form {
    right: 0;
  }
}

@media screen and (min-width: 40.625em) {
  .main-navigation .search-form {
    position: relative;
    top: auto;
    flex: 1 1 auto;
    order: -1;
    width: 40%;
  }
}

@media screen and (min-width: 57.8125em) {
  .main-navigation .search-form {
    position: absolute;
    top: .5rem;
    width: 30%;
  }
}

/* Footer */
.site-footer {
  padding: 0 1rem 1rem;
  font-size: .8125rem;
}

.site-footer * + * {
  margin-top: .25em;
}

@media screen and (min-width: 28.125em) {
  .site-footer {
    padding: 0;
  }
}

@media screen and (min-width: 40.625em) {
  .site-footer__left {
    width: 65%;
    float: left;
  }
  .site-footer__right {
    position: relative;
    float: right;
    width: 30%;
  }
}

.social-navigation {
  margin-top: 1rem;
}

.sponsors {
  position: relative;
  margin-bottom: 1rem;
}

.sponsors a {
  color: #6d6e6f;
  text-decoration: none;
}

.footerlogo {
  display: none;
  /* initial state */
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
}

@media screen and (min-width: 40.625em) {
  .footerlogo {
    text-align: right;
  }
}

.footerlogo:first-child {
  display: block;
}

.footerlogo__image {
  display: inline-block;
  background-blend-mode: multiply;
  background-size: cover;
}

.footerlogo__image img {
  position: relative;
  z-index: -1;
}

/*******************************************
 *
 * Home Picture Box
 *
 *******************************************/
.home-hero {
  position: relative;
  overflow: hidden;
}

.home-hero * {
  margin-top: 0;
}

.home-hero__text {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  background: rgba(0, 65, 88, 0.85);
  color: #fff;
  font-size: .875rem;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 40.625em) {
  .home-hero__text {
    position: absolute;
    top: 0;
  }
}

.home-hero__text a {
  color: #fff;
}

.home-hero__title {
  font-weight: normal;
  color: #fff;
  text-align: center;
}

.home-hero__title strong {
  display: block;
}

@media screen and (min-width: 40.625em) {
  .home-hero__title {
    font-size: 2.5vw;
    text-align: left;
  }
  .home-hero__title strong {
    display: inline;
  }
}

@media screen and (min-width: 57.8125em) {
  .home-hero__title {
    font-size: 1.75vw;
  }
}

@media screen and (min-width: 83.5em) {
  .home-hero__title {
    font-size: 1.333333rem;
  }
}

.home-hero__title:after {
  display: none;
}

.home-hero__toggle-button {
  position: absolute;
  top: 100%;
  width: 3rem;
  margin-top: 1rem;
  right: 1rem;
  opacity: .8;
  transition: all .25s ease-in-out;
}

.home-hero__toggle-button svg {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}

.home-hero__toggle-button:hover, .home-hero__toggle-button:focus {
  cursor: pointer;
  opacity: 1;
}

.home-hero__toggle-button:focus {
  outline: none;
  box-shadow: none;
}

.home-hero__toggle-button:focus svg {
  filter: drop-shadow(0px 0px 5px #0066ff);
}

.home-hero__caption {
  display: none;
  margin: 1em;
  font-size: .8125rem;
}

.home-hero__close-icon {
  display: none;
}

.is-open .home-hero__close-icon {
  display: inline-block;
}

.home-hero__info-icon {
  display: none;
}

.js .home-hero__info-icon {
  display: inline-block;
}

.is-open .home-hero__info-icon {
  display: none;
}

/*******************************************
 *
 * News Sidebar
 *
 *******************************************/
.commons-heading:after {
  display: none;
}

.home-news * + * {
  margin-top: .5em;
}

.home-news__title {
  position: relative;
  padding-right: 3rem;
  padding-bottom: .5em;
  min-height: 2rem;
  font-size: inherit;
  font-weight: normal;
}

.home-news__title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: .5em;
}

.home-news__title a {
  margin-top: 0;
}

.home-news__title svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  margin-right: 0;
}

.flexbox .home-news__title {
  padding-right: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.flexbox .home-news__title a {
  flex: 1 1 auto;
}

.flexbox .home-news__title svg {
  position: static;
  transform: none;
  margin-left: .5rem;
}

/*******************************************
 *
 * "Infographic columns"
 *
 *******************************************/
.home-infographic .col * + * {
  margin-top: 1em;
}

.home-infographic h3 {
  font-size: 1rem;
}

.home-infographic img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 28.125em) and (max-width: 40.625em) {
  .home-infographic .col-row {
    display: block;
  }
  .home-infographic .col {
    clear: both;
    overflow: hidden;
    width: 100%;
    float: none;
    margin-bottom: 1rem;
  }
  .home-infographic .col img {
    float: left;
    max-width: 35%;
    margin-right: 1rem;
  }
  .home-infographic .col p {
    width: auto;
    overflow: hidden;
  }
}

/* could theoretically be compiled and loaded separately only on required pages */
/* Margins & Padding */
#directory {
  padding-top: 1rem;
  /* top margin is collapsing for some reason: ; */
}

#top-area,
#center-area,
#results-area,
#middle-area,
#middle-area #left-area,
#center-top-area,
.faceted-results article *,
.faceted-resources-results article *,
.faceted-results,
#center-and-left-area,
.faceted-widgets *,
.listingBar * {
  margin-top: 0;
}

.faceted-left-widgets,
#narrow-results,
.faceted-widget + .faceted-widget {
  margin-top: 1em;
}

.faceted-criteria .plain-button {
  padding: 0;
  color: #2f637a;
  text-decoration: underline;
}

.faceted-criteria .plain-button:hover, .faceted-criteria .plain-button:focus {
  text-decoration: none;
}

/* Facets */
.faceted-text-widget input[type=radio],
.faceted-text-widget label {
  display: none;
}

/* Search widget */
@media screen and (min-width: 57.8125em) {
  .faceted-left-widgets .faceted-text-widget form {
    display: flex;
  }
  .faceted-left-widgets .faceted-text-widget input[type="text"] {
    flex: 1 1 auto;
    margin-right: 5px;
    width: 50%;
  }
}

/* Shared Checkbox Styles for Filters & Facets */
.faceted-checkboxes-widget input[type="checkbox"],
.faceted-checkboxes-widget input[type="checkbox"] + label {
  cursor: pointer;
}

.faceted-checkboxes-widget input[disabled],
.faceted-checkboxes-widget input[disabled] + label {
  opacity: .55;
  cursor: default;
}

/* Areas of expertise */
.faceted-nestedcheckboxes-widget {
  position: relative;
  /* contain the back button on medium screens */
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.faceted-nestedcheckboxes-widget legend {
  font-weight: bold;
}

.faceted-nestedcheckboxes-widget ul {
  list-style: none;
  margin-left: 0;
}

.faceted-nestedcheckboxes-widget .ArchetypesMultiSelectionWidget {
  margin-top: 1rem;
}

.faceted-nestedcheckboxes-widget label {
  display: block;
  margin-top: .5em;
  padding-left: .5em;
  font-size: 1.25rem;
  text-indent: -1.4em;
  padding-left: 1.5em;
  page-break-inside: avoid;
  break-inside: avoid;
}

@media screen and (min-width: 57.8125em) {
  .faceted-nestedcheckboxes-widget label {
    font-size: inherit;
  }
}

.faceted-nestedcheckboxes-widget label:hover {
  cursor: pointer;
  background-color: #f2f2f2;
  outline: 1px solid #f2f2f2;
}

.faceted-nestedcheckboxes-widget label:first-of-type {
  margin-top: 0;
}

.faceted-nestedcheckboxes-widget input {
  margin-right: .25rem;
  transform: scale(1.25);
}

@media screen and (min-width: 57.8125em) {
  .faceted-nestedcheckboxes-widget input {
    transform: none;
  }
}

.faceted-nestedcheckboxes-widget .subareas {
  display: none;
  font-size: .75rem;
}

@media screen and (min-width: 28.125em) {
  .faceted-nestedcheckboxes-widget .subareas {
    column-count: 2;
  }
}

@media screen and (min-width: 57.8125em) {
  .faceted-nestedcheckboxes-widget .subareas {
    column-count: 1;
  }
}

.faceted-nestedcheckboxes-widget .subareas label {
  margin-top: .25em;
}

@media screen and (min-width: 28.125em) {
  .faceted-nestedcheckboxes-widget fieldset > div {
    column-count: 2;
  }
}

@media screen and (min-width: 57.8125em) {
  .faceted-nestedcheckboxes-widget fieldset > div {
    column-count: 1;
  }
}

.faceted-nestedcheckboxes-widget.has-selections fieldset > div {
  column-count: 1;
}

/* Facet States */
/* Expand / Collapse States */
.faceted-nestedcheckboxes-widget form {
  display: none;
}

@media screen and (min-width: 40.625em) {
  .faceted-nestedcheckboxes-widget form {
    display: block;
  }
}

.is-expanded.faceted-nestedcheckboxes-widget form {
  display: block;
}

.facet-toggle {
  width: 100%;
}

.is-expanded .facet-toggle {
  margin-bottom: .5em;
}

@media screen and (min-width: 40.625em) {
  .facet-toggle {
    display: none;
  }
}

/* Reset States */
.facet-reset {
  display: none;
  margin-top: 0.5rem;
}

@media screen and (min-width: 40.625em) and (max-width: 56.25em) {
  .facet-reset {
    position: absolute;
    top: 0;
    right: 0.5rem;
  }
}

.has-selections .facet-reset {
  display: block;
}

.has-selections .subareas,
.has-selections label:not(.is-selected) {
  display: none;
}

.is-selected {
  font-weight: bold;
  border-bottom: 1px solid #bcbdc1;
  margin-bottom: 0.5rem;
  /*
    page-break-after: always;
    break-after: always;
    @include breakpoint( $medium ) {
        page-break-after: auto;
        break-after: auto;
    }
    */
  /*
    input {
        @extend %screen-reader-text;
    } */
}

.is-selected:hover {
  background: transparent !important;
  outline: transparent !important;
  cursor: default !important;
}

.is-selected + .subareas,
.is-selected + .subareas label {
  display: block !important;
}

/* Filters */
#narrow-results {
  display: none;
}

@media screen and (min-width: 40.625em) {
  #narrow-results {
    display: block;
  }
}

.faceted-center-column {
  display: none;
  margin-top: 0;
}

.has-results .faceted-center-column {
  display: block;
}

.faceted-center-column .faceted-widgets {
  display: none;
}

@media screen and (min-width: 40.625em) {
  .faceted-center-column .faceted-widgets {
    display: flex;
    margin-bottom: 1rem;
  }
}

.faceted-center-column .faceted-widget {
  flex: 1 1 auto;
  margin: 0 10px 0 0;
  position: relative;
  height: auto;
  border: 0;
}

.faceted-center-column .faceted-widget:last-child {
  margin-right: 0;
}

.no-flexbox .faceted-center-column .faceted-widget {
  width: 23%;
  margin: 0 1%;
  float: left;
}

.faceted-center-column .faceted-widget .toggle-button {
  display: block;
  width: 100%;
  top: 0;
  margin: 0;
  float: none;
  padding: .5rem;
  border: 1px solid #ddd;
  background-color: transparent !important;
  font-size: .8125rem;
  font-weight: normal;
  line-height: 1;
}

.faceted-center-column .faceted-widget .toggle-button:before {
  content: '+';
  display: inline-block;
  width: 1em;
  margin-right: .25em;
  text-align: center;
  font-weight: bold;
  color: #ddd;
  speak: none;
  text-shadow: 1px 1px #000, 1px -1px #000, -1px 1px #000, -1px -1px #000, 1px 0px #000, 0px 1px #000, -1px 0px #000, 0px -1px #000;
}

.faceted-center-column .faceted-widget .toggle-button[aria-expanded="true"] {
  border-bottom-color: #fff;
  z-index: 100;
}

.faceted-center-column .faceted-widget .toggle-button[aria-expanded="true"]:before {
  margin-top: -1px;
  content: '–';
}

.faceted-center-column .faceted-widget .toggle-button[disabled] {
  opacity: .8;
  pointer-events: none;
}

.faceted-center-column .faceted-widget .toggle-button[disabled]:before {
  color: #000;
  content: '(0)';
  display: inline;
  text-shadow: none;
  font-weight: normal;
}

.faceted-center-column .faceted-widget .toggle-button:focus {
  box-shadow: none;
  border-color: #333;
}

.faceted-center-column .faceted-widget .toggle-container {
  position: absolute;
  top: 100%;
  margin-top: -1px;
  min-width: 100%;
  max-height: 320px;
  overflow: auto;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
  border: 1px solid #ddd;
  background-color: #fff;
}

.faceted-center-column .faceted-widget .toggle-container[aria-hidden="true"] {
  display: none;
}

.faceted-center-column .faceted-widget .toggle-container ul {
  list-style: none;
  margin-left: 0;
}

.faceted-center-column .faceted-widget .toggle-container li {
  margin-top: .25em;
  white-space: nowrap;
}

.faceted-checkbox-item-zerocount {
  display: none;
}

/* Results */
#search-result-header {
  display: none;
}

.has-results #search-result-header {
  display: block;
}

.faceted-results article,
.faceted-resources-results article {
  font-size: .875rem;
}

#faceted-results .tileBody p:last-child,
#faceted-results article p:last-child {
  font-size: .75rem;
  color: #4a433a;
}

/* TEMP STYLES? */
/*******************************************
 *
 * Profiles
 *
 *******************************************/
/* Weird Cleanup */
#providerinfo-main > h1#parent-fieldname-title,
#providerinfo-main > br {
  display: none;
}

.providercontactphone,
.providercontactmobile,
.providercontactfax,
.providercontactemail,
.member-map {
  margin-top: 0;
}

/* Logo */
.providerlogo {
  display: block;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 28.125em) {
  .providerlogo {
    float: right;
    display: inline-block;
    max-width: 40%;
    margin-left: 1rem;
  }
}

/* Areas of Expertise */
.providerexpertise {
  clear: right;
  margin-top: 1rem;
}

@media screen and (min-width: 40.625em) {
  .providerexpertise {
    float: right;
    width: 40%;
    margin-left: 1rem;
  }
  .providerexpertise + h2 {
    margin-top: 1rem;
  }
}

.providerexpertise h2,
.providerbackground h2,
.providermaterials h2 {
  padding: .5rem 1rem;
  background-color: #2f637a;
  font-size: 1rem;
  color: #fff;
}

.providerexpertise h2:after,
.providerbackground h2:after,
.providermaterials h2:after {
  display: none;
}

.providerexpertisebody,
.providerbackgroundbody,
.promomaterialsbody {
  margin-top: 0;
  margin-bottom: .5rem;
  padding: 1rem;
  font-size: .875rem;
  background-color: #f2f2f2;
}

.providerexpertisebody ul,
.providerbackgroundbody ul,
.promomaterialsbody ul {
  margin-top: .5em;
  margin-left: 0;
  margin-bottom: 1rem;
}

.providerexpertisebody li,
.providerbackgroundbody li,
.promomaterialsbody li {
  display: block;
  list-style: none;
}

.providerexpertisebody h3,
.providerbackgroundbody h3,
.promomaterialsbody h3 {
  margin-top: 0;
  font-size: .875rem;
  font-weight: bold;
  padding-left: 1rem;
  text-indent: -1rem;
  line-height: 1.25;
}

@media screen and (min-width: 28.125em) and (max-width: 40.625em) {
  .providerexpertisebody,
  .providerbackgroundbody,
  .promomaterialsbody {
    column-count: 2;
    column-gap: 1rem;
  }
  .providerexpertisebody h3, .providerexpertisebody ul,
  .providerbackgroundbody h3,
  .providerbackgroundbody ul,
  .promomaterialsbody h3,
  .promomaterialsbody ul {
    page-break-inside: avoid;
    page-break-before: avoid;
    break-inside: avoid;
    break-before: avoid;
  }
}

/********************************
 * Leadership Directory
 ********************************/
.ldp-result,
.ldp-result details,
.ldp-result summary {
  overflow: hidden;
}

.ldp-result details {
  padding: 1rem;
  border: 1px solid #ddd;
}

.ldp-result summary {
  box-sizing: content-box;
  position: relative;
  left: -1rem;
  width: 100%;
  padding: 0 1rem;
  cursor: pointer;
}

.no-details .ldp-result summary {
  cursor: default;
}

/* hide default browser details markers  */
.ldp-result details summary {
  list-style-type: none;
}

.ldp-result details summary::-webkit-details-marker {
  display: none;
}

.ldp-result details[open] {
  padding-bottom: 1em;
  background-image: linear-gradient(to top, #eee, #fff 20%);
}

.ldp-result summary:focus {
  outline: 0;
}

/* If no support for <details> element */
.no-details .ldp-result-more {
  display: none;
}

.ldp-result-more {
  display: inline-block;
  cursor: pointer;
  padding: .25em .5em;
  border-radius: 3px;
  border: 1px solid transparent;
  transition: all .125s linear;
}

.ldp-result summary:focus .ldp-result-more {
  border-color: #2f637a;
}

.ldp-result summary:hover .ldp-result-more {
  background-color: #2f637a;
  color: #fff !important;
}

.ldp-result-more::before {
  display: inline-block;
  content: '';
  height: 0;
  width: 0;
  margin-right: .125em;
  border: .375em solid transparent;
  vertical-align: 0;
  border-left-color: #222;
  border-left-color: currentColor;
  transform-origin: 25% 50%;
  transition: transform .25s linear;
}

details[open] .ldp-result-more::before {
  transform: rotate(90deg);
}

.ldp-results-list .ldp-result * + * {
  margin-top: 1.5em;
}

/* Header */
.ldp-result .ldp-result-title {
  margin-top: 0;
}

.ldp-result .ldp-result-org {
  display: flex;
  align-items: center;
  margin-top: .5em;
  font-size: 15px;
  font-size: .8333rem;
}

.ldp-result-directory-icon {
  position: relative;
  margin-right: .5em;
  cursor: help;
}

.ldp-result-directory-icon:focus .ldp-directory-icon-tooltip,
.ldp-result-directory-icon:hover .ldp-directory-icon-tooltip {
  display: inline-block;
  clip: auto;
  width: auto;
  height: auto;
  white-space: nowrap;
  left: 0;
  top: 125%;
  padding: .25em .75em;
  margin-top: 0;
  background: #2f637a;
  color: #fff;
  border-radius: 16px;
}

/* meta */
.ldp-result-details > *,
.ldp-result-expanded-details > *,
.ldp-result-topic-list {
  display: inline-block;
  color: #555;
  font-size: 15px;
  font-size: .8333rem;
}

.ldp-results-list .ldp-result dt,
.ldp-results-list .ldp-result dd {
  display: inline-block;
  margin-left: 0;
  margin-top: 0;
}

.ldp-results-list .ldp-result dl {
  display: block;
}

.ldp-result dd::after {
  content: ', ';
}

.ldp-result dd:last-of-type::after {
  display: none;
}

.ldp-result-detail-date > span {
  display: inline-block;
  /* ensure single date stays on line if wrapping */
}

/* Body */
.ldp-register-link {
  font-weight: bold;
}

.ldp-result-expanded-description h4 {
  margin-top: 1.5em;
}

@media screen and (min-width: 0) and (max-width: 56.25em) {
  .ldp-result .ldp-result-details > * {
    margin-top: 0;
  }
  .ldp-result-details > * + span::before {
    display: inline-block;
    content: '';
    width: 1px;
    height: .75em;
    background-color: #555;
    margin: 0 .25em;
  }
  .ldp-result-details dd,
  .ldp-result-details dt {
    display: inline-block;
    font-weight: normal;
  }
}

/* large-screen layout */
@media screen and (min-width: 57.8125em) {
  .ldp-result .ldp-result-details,
  .ldp-result .ldp-result-summary,
  .ldp-result .ldp-result-expanded-details,
  .ldp-result .ldp-result-expanded-description {
    margin-top: 1.5em;
  }
  .ldp-result-details,
  .ldp-result-expanded-details {
    float: left;
    width: 30%;
    margin-right: 5%;
  }
  .ldp-result .ldp-result-details > * + *,
  .ldp-result .ldp-result-expanded-details > * + * {
    margin-top: 1em;
  }
  .ldp-result-summary,
  .ldp-result-expanded-description {
    float: right;
    width: 65%;
  }
  .ldp-result-expanded-description {
    clear: right;
  }
  .ldp-result-expanded-details {
    clear: both;
  }
  .ldp-result-details > *,
  .ldp-result-expanded-details > * {
    display: block;
  }
  .ldp-results-list .ldp-result dt {
    display: block;
  }
}

/********************************
 * Filter Designs
 ********************************/
.faceted-checkboxes-widget ul,
.faceted-checkboxes-widget li {
  list-style: none;
}

.faceted-checkboxes-widget ul {
  margin-top: 1em;
  margin-left: 0;
}

/* Leadership Directory Search Field */
.subsection-offerings-offering-collection #c4_widget.faceted-text-widget br {
  display: none;
}

.subsection-offerings-offering-collection #c4_widget.faceted-text-widget legend {
  padding-bottom: .25em;
  font-size: 14px;
  font-size: .777rem;
  font-weight: bold;
}

.subsection-offerings-offering-collection #c4_widget.faceted-text-widget form + p:last-child {
  margin-top: .5em;
  font-size: 14px;
  font-size: .777rem;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget .toggle-button {
  display: block;
  width: 100%;
  padding: 0 0 .25em;
  border: 0;
  border-bottom: 1px solid #ccc;
  background: transparent;
  font-size: 14px;
  font-size: .777rem;
  font-weight: bold;
  text-align: left;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget .toggle-button:hover {
  box-shadow: 0 2px 0 #ccc;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget .toggle-button:focus {
  box-shadow: 0 2px 0 #2f637a;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget .toggle-button::before {
  display: inline-block;
  content: '';
  height: 0;
  width: 0;
  margin-right: .25em;
  border: .4375em solid transparent;
  vertical-align: 0;
  border-left-color: #222;
  border-left-color: currentColor;
  transform-origin: 25% 50%;
  transition: transform .25s linear;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget .toggle-button[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget input {
  margin-right: .25em;
}

.faceted-left-widgets .toggle-container[aria-hidden=true] {
  display: none;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget li {
  display: flex;
  align-items: flex-start;
  margin-bottom: .25em;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget input[type="checkbox"] {
  line-height: 1.5;
  margin: .3em .5em 0 0;
}

.faceted-left-widgets .faceted-collapsingcheckbox-widget label {
  font-size: 14px;
  font-size: .777rem;
}

@media print {
  /* white background, good font-size */
  html,
  :root {
    font-size: .9375rem !important;
    background: #fff;
  }
  body {
    font-size: 1rem;
  }
  main {
    padding: 0;
    border-top: 0;
  }
  /* Hide stuff */
  .main-navigation,
  .footer-navigation,
  .social-navigation,
  .sponsors,
  .site-sidebar {
    display: none;
  }
  /* Nice logo & tagline */
  .site-header {
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
  }
  .site-title {
    width: 90px;
    float: left;
  }
  .site-tagline {
    display: block;
    float: right;
  }
  /* URLs after body content links */
  .site-content .entry-content a:not(.button):after {
    content: " [" attr(href) "]";
  }
  /* A few layout things pulled in from other sources */
  .image-left {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }
  .image-right {
    float: right;
    margin: 0.5em;
  }
  .image-inline {
    float: none;
  }
  .image-right,
  .image-left {
    max-width: 60%;
  }
  /*note: the tricky thing here is that image-inline|image-left|image-right
    can both be on an image and a wrapper element e.g. a dl tag*/
  .captioned img {
    border: 1px solid #ddd;
  }
  dd.image-caption {
    text-align: left;
    padding: 0 0 0 3px;
    /*image padding+border with*/
    margin: 0;
    color: #666;
    font-size: 85%;
  }
}

/*# sourceMappingURL=maps/screen.css.map */